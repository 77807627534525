<template>
  <div class="container">
    <div class="head">
      <div class="head_image">
        <img v-if="logoimg" :src="logoimg" alt="" />
        <img v-if="!logoimg" src="@/assets/newskin/logo.png" alt />
      </div>
      <div class="head_search">
        <md-field>
          <md-input-item
            placeholder="请输入产品名称"
            v-model="searchkey"
            clearable
          >
            <md-icon name="search" size="lg" slot="left"></md-icon>
          </md-input-item>
        </md-field>
      </div>
      <div class="head_search_btn">
        <md-field>
          <md-button @click="searchBtn">搜索</md-button>
        </md-field>
      </div>
    </div>
    <!-- 轮播图 -->
    <div class="banner">
      <md-swiper :autoplay="3000" :is-prevent="false">
        <template v-if="havebanner">
          <md-swiper-item v-for="(item, index) in swiperList" :key="index">
            <div class="swiperimg" @click="goTodetail(item)">
              <img :src="ossurl + item.pichttp" alt />
            </div>
          </md-swiper-item>
        </template>
        <template v-if="!havebanner">
          <md-swiper-item>
            <img src="@/assets/newskin/swiper1.png" alt />
          </md-swiper-item>
        </template>
      </md-swiper>
    </div>

    <!-- 按钮 -->
    <div class="menu_bar">
      <div class="menu_box" @click="$router.push('/teamzy')">
        <img
          class="menu_box_img"
          src="@/assets/newskin/modulezengyuan.png"
          alt
        />
        <span>增员</span>
      </div>
      <div class="menu_box" @click="$router.push('/simplex')">
        <img
          class="menu_box_img"
          src="@/assets/newskin/modulechudangonghao.png"
          alt
        />
        <span>出单工号</span>
      </div>
      <div class="menu_box" @click="$router.push('/productlist')">
        <img
          class="menu_box_img"
          src="@/assets/newskin/modulejihuashu.png"
          alt
        />
        <span>计划书</span>
      </div>
      <div class="menu_box" @click="$router.push('/drinking')">
        <img
          class="menu_box_img"
          src="@/assets/newskin/modulechanpinduibi.png"
          alt
        />
        <span>产品对比</span>
      </div>
      <div class="menu_box" @click="$router.push('/newpolicy')">
        <img
          class="menu_box_img"
          src="@/assets/newskin/modulexinznengbaodan.png"
          alt
        />
        <span>新保保单</span>
      </div>
      <div class="menu_box" @click="$router.push('/renewal')">
        <img class="menu_box_img" src="@/assets/newskin/modulexuqi.png" alt />
        <span>续期保单</span>
      </div>
      <div class="menu_box" @click="$router.push('/preservation')">
        <img
          class="menu_box_img"
          src="@/assets/newskin/modulebaoquan.png"
          alt
        />
        <span>保全业务</span>
      </div>
      <div class="menu_box" @click="$router.push('/sett')">
        <img class="menu_box_img" src="@/assets/newskin/modulelipei.png" alt />
        <span>理赔业务</span>
      </div>
    </div>
    <!-- <div class="interval"></div> -->
    <!-- 任务提醒 -->
    <div class="task_box">
      <div class="task_head">
        <span>任务提醒</span>
        <span @click="$router.push('/reminders')">
          查看更多
          <span>
            <md-icon name="arrow-right"></md-icon>
          </span>
        </span>
      </div>
      <div class="task_content">
        <div class="task_card" @click="$router.push('/reminders?alerttype=1')">
          <p class="task_card_title">
            <span class="task_card_title_one"></span>
            <span>即将生日客户</span>
          </p>
          <p class="task_card_num">
            <span>{{ taskList[1] }}</span>
          </p>
          <div class="task_card_img">
            <img src="@/assets/newskin/remindliwu.png" alt />
          </div>
        </div>
        <div class="task_card" @click="$router.push('/reminders?alerttype=2')">
          <p class="task_card_title">
            <span class="task_card_title_two"></span>
            <span>车险即将到期客户</span>
          </p>
          <p class="task_card_num">
            <span>{{ taskList[2] }}</span>
          </p>
          <div class="task_card_img">
            <img src="@/assets/newskin/remindqiche.png" alt />
          </div>
        </div>
        <div class="task_card" @click="$router.push('/reminders?alerttype=3')">
          <p class="task_card_title">
            <span class="task_card_title_three"></span>
            <span>续期缴费客户</span>
          </p>
          <p class="task_card_num">
            <span>{{ taskList[3] }}</span>
          </p>
          <div class="task_card_img">
            <img src="@/assets/newskin/remindqian.png" alt />
          </div>
        </div>
        <div class="task_card" @click="$router.push('/reminders?alerttype=4')">
          <p class="task_card_title">
            <span class="task_card_title_four"></span>
            <span>保险期满客户</span>
          </p>
          <p class="task_card_num">
            <span>{{ taskList[4] }}</span>
          </p>
          <div class="task_card_img">
            <img src="@/assets/newskin/reminddai.png" alt />
          </div>
        </div>
      </div>
      <!-- <div class="coupon_box" @click="$router.push('/morermhd')">
        <img :src="getBossurl + 'kaquan.png'" alt />
      </div> -->
    </div>
    <!-- 展业海报 -->
    <div class="zyhb_box">
      <div class="task_head assess_head">
        <span>获客海报</span>
        <span @click="$router.push('/acquisitionposter')">
          查看更多
          <span>
            <md-icon name="arrow-right"></md-icon>
          </span>
        </span>
      </div>
      <div class="hbbox">
        <div class="poster" v-for="(item, index) in hblist" :key="index">
          <div class="imgTitle" @click="$router.push('/acquisitionposter')">
            <img class="img" :src="ossurl + item.pichttp" />
          </div>
          <div class="imgbtn" @click="use(item.sno)">点击使用</div>
        </div>
      </div>
    </div>
    <!-- 热门文章 -->
    <div class="assess_box">
      <div class="task_head assess_head">
        <span>热门文章</span>
        <span @click="$router.push('/Hkbw')">
          查看更多
          <span>
            <md-icon name="arrow-right"></md-icon>
          </span>
        </span>
      </div>
      <div
        class="assess_con_box"
        v-for="(item, index) in hostActile"
        @click="goTohostactive(item.sno)"
        :key="index"
      >
        <div class="assess_img_box">
          <div
            class="img"
            :style="{
              'background-image': 'url(' + ossurl + item.pichttp + ')',
            }"
          ></div>
          <div class="assess_img_icon">
            <img :src="getBossurl + 'baoding74.png'" alt />
            <!-- <img src="@/assets/home/12保 定-74.png"
                 alt /> -->
          </div>
        </div>
        <div class="rtime_read_box">
          <p class="assess_title">{{ item.stitle }}</p>
          <p class="assess_content_box">{{ item.sdesc }}</p>
          <div class="time_read_box">
            <div class="time_box">
              <!-- <img :src="getBossurl + 'baoding75.png'" alt /> -->
              <img src="@/assets/newskin/essaytime.png" alt />
              <span
                >{{ item.fbtime.monthValue }}-{{ item.fbtime.dayOfMonth }}</span
              >
            </div>
            <div class="read_box">
              <img :src="getBossurl + 'baoding76.png'" alt />
              <img src="@/assets/newskin/essayren.png" alt />
              <span>{{ item.ydnum }}阅读</span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="page >= 2"
        class="show_more show_more_pordectbox"
        @click="$router.push('/Hkbw')"
      >
        <div class="show_more_pordect">查看更多</div>
      </div>
      <div v-else class="show_more">加载更多...</div>
    </div>
    <nhsx></nhsx>
    <md-landscape v-model="showPic">
      <div class="showsctp"></div>
    </md-landscape>
    <div class="vertex" @click="crown" v-if="crownshow">
      <img src="@/assets/newskin/top.png" alt />
    </div>
  </div>
</template>

<script>
import { getStorage, setStorage } from "@/lib/util";
import nhsx from "@/views/basic/home/component/nhsx";
// import sasscv from '@/views/basic/home/component/sasscv'
import {
  selectPage,
  getTypeCount,
  hostproduct,
  // getswiper,
  gettaskList,
  gethostActile,
  toBdtg,
} from "@/api/basic/index";
import { useHB } from "@/api/abt/customerOperation/common";
import { Toast } from "mand-mobile";
export default {
  components: {
    nhsx,
  },
  data() {
    return {
      hostproductList: [], // 爆款
      ossurl: getStorage("ossurl", ""),
      entryflag: false,
      logoimg:
        getStorage("sys_info", "").sylogo &&
        getStorage("sys_info", "").sylogo !== this.ossurl
          ? getStorage("sys_info", "").sylogo
          : "",
      swiperList: [], // 轮播图
      taskList: [], // 任务提醒
      page: 1,
      hostActile: [],
      getBossurl: "",
      searchkey: "",
      antiShake: true, // 防抖,
      havebanner: true,
      hblist: [],
      colorprimary: "",
      showPic: false,
      crownshow: false,
      // 记录屏幕滑动的值
      scrollTop: 0,
    };
  },
  created() {
    // setStorage('theme', res.data.data.menustyle ? (res.data.data.menustyle.colour ? res.data.data.menustyle.colour : '#11579D') : '#11579D')
    // setStorage("theme", "#FF5A36");
    this.getBossurl = getStorage("tbossurl", "");
    this.gethostproduct();
    this.onEndReached();
    this.gethbdata();
    this.user = getStorage("u_s", {});
    this.entryflag = getStorage("entryflag", "");
    console.log(this.entryflag);
    this.colorprimary = getStorage("theme", "");
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
    console.log(document.getElementsByName("swiper"));
    setTimeout(() => {
      this.swiperList = getStorage("banner", []);
      this.swiperList.length == 0
        ? (this.havebanner = false)
        : (this.havebanner = true);
    }, 500);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  activated() {
    // hostproduct().then((res) => {
    //   this.hostproductList = res.data.data
    // })
    // banner 接口
    // getswiper()
    //   .then((res) => {
    //     // this.swiperList = []
    //     this.swiperList = res.data.data.rows
    //     if (this.swiperList.length > 0) {
    //       this.havebanner = true
    //     } else {
    //       this.havebanner = false
    //     }
    //   })
    //   .catch(() => {
    //     this.havebanner = false
    //   })
  },
  methods: {
    gethbdata() {
      let data = {
        page: 1,
        size: 4,
        btagcode: "2",
        stagcode: "",
        flag: "0",
      };
      selectPage(data).then((res) => {
        this.hblist = res.data.data.rows;
      });
    },
    crown() {
      window.scrollTo(0, 0);
      // const that = this;
      // let timer = setInterval(() => {
      //   let ispeed = Math.floor(-that.scrollTop / 5)
      //   document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
      //   if (that.scrollTop === 0) {
      //     clearInterval(timer)
      //   }
      // }, 16)
    },
    scrollToTop() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 60) {
        that.crownshow = true;
      } else {
        that.crownshow = false;
      }
    },
    use(val) {
      Toast.loading("生成中...");
      $(document).ready(function () {
        $(".showsctp").empty();
      });
      this.sctp = "";
      useHB({ sno: val, flag: "2", rytype: this.user.rytype }).then((res) => {
        this.sctp = this.ossurl + res.data.data.url;
        let aaa = "<img src='" + this.sctp + "' alt='' />";
        $(document).ready(function () {
          $(".showsctp").append(aaa);
        });
        this.showPic = true;
        Toast.hide();
      });
    },
    gethostproduct() {
      // hostproduct().then((res) => {
      //   this.hostproductList = res.data.data
      // })
      // getswiper()
      //   .then((res) => {
      //     // this.swiperList = []
      //     // this.swiperList = res.data.data.rows
      //     this.swiperList = res.data.data.rows
      //     if (this.swiperList.length > 0) {
      //       this.havebanner = true
      //     } else {
      //       this.havebanner = false
      //     }
      //   })
      //   .catch(() => {
      //     this.havebanner = false
      //   })
      getTypeCount().then((res) => {
        this.taskList = res.data.data;
      });
      let data = {
        page: this.page,
        size: 5,
        btagcode: "1",
        stagcode: "",
        flag: "0",
      };
      selectPage(data).then((res) => {
        this.hostActile = res.data.data.rows;
      });
    },
    // 轮播图跳转 链接类型:W文章，P产品，O外部链接
    goTodetail(val) {
      // console.log(val.urlhttp)
      if (val.urltype === "W") {
        this.$router.push({
          name: "showfome",
          path: "/showfome",
          params: { locationhref: this.ossurl + val.urlhttp },
        });
      }
      if (val.urltype === "P") {
        // console.log('p')
        if (val.tbflag === "0") {
          val.comtburl = val.urlhttp;
          val.tburl = val.urlhttp;
        } else if (val.tbflag === "1") {
          val.showhttp = val.urlhttp;
        }
        //   if (val.urlhttp.substring(0, 4) === 'http') {
        //     val.tbflag = '0'
        //     val.comtburl = val.urlhttp
        //     val.tburl = val.urlhttp
        // } else {
        //     val.tbflag = '1'
        //     val.showhttp = val.urlhttp
        // }
        this.goTotbflag(val);
      }
      if (val.urltype === "O") {
        if (val.urlhttp.includes("/nhbdtg")) {
          toBdtg({
            url: val.urlhttp,
            empno: this.user.empno,
          }).then((res) => {
            window.location.href = res.data.data;
          });
        } else {
          window.location.href = val.urlhttp;
        }
      }
    },
    // 爆款跳转
    goTotbflag(val) {
      // console.log(val)
      // return
      if (val.tbflag === "1") {
        this.$router.push({
          name: "showhostproduct",
          params: {
            product: val.showhttp
              ? val.showhttp
              : '<p style="margin: 20px auto;">暂无数据</p>',
            iconhttp: "",
            prodname: val.prodname,
          },
        });
      } else if (val.tbflag === "0") {
        // 导入产品跳转不过验签
        if (val.drflag === "1") {
          window.location.href = val.comtburl;
          return;
        }
        // 在线出单产品进行跳转链接处理，跳转到产品详情页
        this.$store
          .dispatch("SALES_PERMISSION", {
            url: val.comtburl,
          })
          .then((res) => {
            if (res.tburl) {
              window.location.href = res.tburl;
            }
          });
      }
    },
    // 爆款文章
    goTohostactive(sno) {
      this.$router.push({
        path: `/Libwarticle?sno=${sno}&empno=${this.user.empno}&suserid=${this.user.userid}`,
      });
    },
    // 触底事件
    onEndReached() {
      let that = this;
      let isfirst = true;
      window.onscroll = function () {
        // console.log(document.documentElement.scrollTop)
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let clientHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        let scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        // console.log('scrollTop: ' + scrollTop + ',' + 'clientHeight: ' + clientHeight + ',' + 'scrollHeight: ' + scrollHeight)
        if (that.page === 1 && isfirst) {
          document.documentElement.scrollTop = 0;
          isfirst = false;
        }
        if (scrollTop + clientHeight >= scrollHeight - 20 && that.antiShake) {
          that.antiShake = false;
          if (that.page >= 2) {
            return;
          }
          let data = {
            page: that.page + 1,
            size: 5,
            btagcode: "1",
            stagcode: "",
            flag: "0",
          };
          selectPage(data).then((res) => {
            that.hostActile = [...that.hostActile, ...res.data.data.rows];
            that.antiShake = true;
            that.page += 1;
          });
        }
      };
    },
    // 搜索
    searchBtn() {
      this.$router.push({
        name: "productDisplayNhsx",
        path: "/productDisplayNhsx",
        params: { search: this.searchkey },
      });
    },
  },
};
</script>

<style scoped lang="stylus">
.container {
  background-color: #fff;
  color: #474747 !important;
}

.head {
  height: 1.4rem;
  width: 100%;
  padding: 0.33rem 0.22rem 0.3rem 0.46rem;
  display: flex;
}

.head_image {
  padding-top: 0.12rem;
}

.head_image img {
  width: 0.58rem;
  height: auto;
}

.head_search {
  width: 7.46rem;
  margin: 0 0.25rem;
  border: 0.01rem solid #f2f2f2;
  border-radius: 0.77rem;
}

.head .md-field {
  padding: 0;
  height: 100%;
  background-color: inherit;
  border-radius: 0.77rem;
}

.head_search .md-field /deep/ .md-field-item-content {
  background-color: #f2f2f2;
  height: 0.77rem;
  padding: 34px;
  border-radius: 0.1rem;
  min-height: 0.77rem !important;
}

div /deep/ .md-field-item-content::before {
  content: '';
  border-bottom: inherit;
}

.head_search /deep/ .md-field-item-left {
  margin: 0 0.23rem 0 0rem;
  color: #a5a5a5;
}

.head_search /deep/ .md-input-item-input {
  font-size: 0.36rem;
  color: #a5a5a5 !important;
}

.head_search_btn .md-field /deep/ .md-button {
  background-color: inherit !important;
  font-weight: normal;
  padding-bottom: 0.21rem;
}

.head_search_btn .md-field /deep/ .md-button .md-button-content {
  font-size: 0.35rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #474747;
}

.banner {
  width: 100%;
  height: 4rem;
  background-color: #fff;
}

.swiperimg {
  height: 100%;
}

.menu_bar {
  height: 3rem;
  margin: 0.35rem 0.74rem 0.46rem 0.94rem;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
}

.menu_box {
  display: flex;
  flex-direction: column;
}

.menu_box:nth-of-type(1) img, .menu_box:nth-of-type(2) img, .menu_box:nth-of-type(3) img {
  // margin-right: 0.98rem;
  /* margin-right: 1.06rem; */
  margin-right: 1.2rem;
}

.menu_box_img {
  // width: 1.23rem;
  // height: 1.23rem;
  width: 0.71rem;
  height: auto;
  margin-left: 0.36rem;
  margin-bottom: 0.18rem;
}

// .menu_box_icon {
// width: 0.59rem;
// height: 0.59rem;
// /* margin: 0 auto; */
// margin-left: 0.1rem;
// margin-bottom: 0.15rem;
// }
.menu_box:nth-of-type(5) img, .menu_box:nth-of-type(6) img, .menu_box:nth-of-type(7) img {
  /* margin-right: 1.3rem; */
  /* margin-left: 0.35rem; */
  margin-right: 1.2rem;
}

.menu_box:nth-of-type(5) img, .menu_box:nth-of-type(6) img, .menu_box:nth-of-type(7) img, .menu_box:nth-of-type(8) img {
  margin-top: 0.3rem;
}

// .menu_box:nth-of-type(8) img {
// margin-left: 0.4rem;
// }
.menu_box span {
  display: block;
  width: 1.4rem;
  word-break: break-all;
  white-space: nowrap;
  font-size: 0.36rem;
  text-align: center;
}

// .interval {
// height: 0.2rem;
// margin-bottom: 0.2rem;
// background-color: #f2f2f2;
// }
.task_box::after {
  content: '';
  overflow: hidden;
  clear: both;
}

.task_head {
  border-bottom: 0.02rem solid #efefef;
  padding-bottom: 0.27rem;
  padding-left: 0.35rem;
  padding-right: 0.33rem;
}

.task_head span:nth-of-type(1) {
  font-size: 0.46rem;
  color: #333333;
  font-weight: bolder;
}

.task_head span:nth-of-type(2) {
  font-size: 0.33rem;
  color: #9b9b9b;
  padding-bottom: 0.1rem;
  float: right;
}

.task_head span:nth-of-type(2) span {
  margin-left: 0.07rem;
  position: relative;
  bottom: 0.004rem;
}

.task_content {
  margin: 0.41rem 0.33rem 0.23rem 0.33rem;
  padding-bottom: 0.3rem;
  display: flex;
  flex-wrap: wrap;
  /* border-bottom: 0.01rem solid #efefef; */
}

.task_card {
  width: 4.5rem;
  height: 1.67rem;
  box-shadow: 0 0 0.18rem #e6e6e6;
  position: relative;
}

.task_card:nth-of-type(1), .task_card:nth-of-type(2) {
  margin-bottom: 0.23rem;
}

.task_card:nth-of-type(1), .task_card:nth-of-type(3) {
  margin-right: 0.29rem;
}

.task_card_title {
  margin-left: 0.41rem;
  margin-top: 0.37rem;
  line-height: 0.31rem;
}

.task_card_title span:nth-of-type(1) {
  display: inline-block;
  width: 0.07rem;
  height: 0.21rem;
  margin-bottom: 0.08rem;
  border-radius: 0.04rem;
  margin-right: 0.15rem;
  position: relative;
  top: 0.05rem;
}

.task_card_title span:nth-of-type(2) {
  font-size: 0.33rem !important;
  color: #383838;
}

.task_card_title_one {
  background-color: #5496ff;
}

.task_card_title_two {
  background-color: #f4523f;
}

.task_card_title_three {
  background-color: #09b7c0;
}

.task_card_title_four {
  background-color: #ffb743;
}

.task_card_num span {
  display: inline-block;
  padding-left: 0.65rem;
  font-size: 0.5rem;
  margin-top: 0.19rem;
}

.task_card_img {
  position: absolute;
  width: 0.63rem;
  height: 0.63rem;
  right: 0.61rem;
  bottom: 0.39rem;
}

.task_card_img img {
  height: auto;
}

// .coupon_box {
// margin-left: 0.21rem;
// margin-right: 0.38rem;
// }
.host_box {
  display: flex;
  padding-bottom: 0.5rem;
}

.host_product {
  background: white;
  /* margin: 0.51rem 0.35rem 0.17rem 0.35rem; */
  margin: 0.35rem 0.35rem 0.17rem 0.35rem;
}

.host_head {
  margin: 0;
  margin-bottom: 0.44rem;
}

.host_head img {
  width: 0.247rem;
  height: 0.32rem;
  margin-right: 0.1rem;
}

.host_content {
  /* width: 3.7rem; */
  width: 3.06rem;
  /* height: 3.06rem; */
}

.host_content:nth-of-type(1), .host_content:nth-of-type(2) {
  margin-right: 0.1rem;
}

.host_con_img {
  width: 3.06rem;
  height: 3.06rem;
  border-radius: 0.21rem;
  overflow: hidden;
  margin-bottom: 0.31rem;
}

.host_con_text span {
  padding-left: 0.12rem;
  font-size: 0.33rem !important;
}

.assess_box {
  background-color: #fff;
  margin-bottom: 1.4rem;
}

/* .assess_box_height{
  height: 10rem;
  overflow: hidden;
} */
.assess_head {
  background-color: #fff;
  margin: 0;
  padding: 0.35rem 0.33rem 0.28rem 0.35rem;
}

.assess_con_box {
  display: flex;
  background-color: #fff;
  padding: 0.36rem 0.35rem 0 0.37rem;
  border-bottom: 0.02rem solid #efefef;
}

.assess_img_box {
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 0.2rem;
  overflow: hidden;
  position: relative;
  margin-right: 0.35rem;
  margin-bottom: 0.35rem;

  .img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    background-size: cover;
    background-position: center;
  }
}

.assess_img_icon {
  width: 0.91rem;
  height: 0.43rem;
  position: absolute;
  right: 0.11rem;
  bottom: 0.09rem;
}

.assess_content_box {
  font-size: 0.33rem;
  margin-bottom: 0.2rem;
  color: #7d7d7d;
  width: 6.75rem;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.assess_title {
  margin-top: 0.1rem;
  /* margin-top: 0.03rem; */
  font-size: 0.36rem;
  font-weight: bold;
  color: #333333;
  padding-bottom: 0.2rem;
  width: 6.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rtime_read_box {
  position: relative;
}

.time_read_box {
  position: absolute;
  bottom: 0.35rem;
  display: flex;
  height: 0.35rem;
}

.time_box, .read_box {
  height: 0.35rem;
  display: flex;
  align-items: center;
}

.time_box img, .read_box img {
  width: 0.33rem;
  height: 0.33rem;
}

.read_box img {
  margin-left: 0.28rem;
}

.time_box span, .read_box span {
  padding-left: 0.13rem;
  font-size: 0.32rem;
  color: #9599a2;
}

.show_more {
  text-align: center;
  color: #9b9b9b;
  margin-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-size: 0.33rem;
}

.show_more_pordectbox {
  height: 1.2rem;
}

.show_more_pordect {
  width: 2.68rem;
  height: 0.8rem;
  line-height: 0.7rem;
  color: #999;
  border: 0.02rem solid #E7E7E7;
  border-radius: 0.5rem;
  margin: 0.46rem auto;
  font-size: 0.32rem;
  font-family: PingFang SC;
  font-weight: 500;
}

/deep/ .md-scroll-view-more {
  padding: 0 !important;
}

.zyhb_box {
  height: 6.8rem;
  background: #fff;
}

.poster {
  float: left;
  width: 25%;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;

  .imgTitle {
    position: relative;

    .img {
      border-radius: 20px;
      position: relative;
      width: 100%;
      height: 379px;
    }

    .sxNew {
      position: absolute;
      right: 0;
      width: 150px;
      height: auto;
      z-index: 999;
    }
  }

  .imgbtn {
    width: 200px;
    margin: 20px auto;
    padding: 10px 30px;
    border-radius: 10px;
    font-size: 30px;
    color: #ED353F;
    border: 1px solid #F05B63;
  }
}

/deep/.md-landscape-content {
  width: 740px;
}

/deep/ .scroll-view-container {
  margin-top: 0.1rem;
}

.vertex {
  position: fixed;
  top: 80%;
  right: 0.35rem;

  img {
    width: 1.04rem;
    height: 1.04rem;
  }
}
</style>
